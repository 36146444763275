import { html } from "lit";
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import { pureLit, useState, dispatch, useOnce } from "pure-lit";
import { itemTag, itemList, blogList } from "./items.styles.js"

const decoratedWithStyles = (data) => 
    `${data}${[...document.querySelectorAll("link[rel=stylesheet]")].map(node => node.outerHTML).join("")}`

pureLit("lazy-home", async (el) => {
    const home = await fetch(`lazy.html`)
        .then(r => r.text())

    setTimeout(() => dispatch(el, "loaded", true), 50)
    return html`${unsafeHTML(home)}`
})

pureLit("lazy-html", async (el) => {
    const {page} = el;
    if (page === "") return html``
    const home = await fetch(page)
        .then(r => r.text())

    useOnce(el, async () => {
        const styles = [...document.querySelectorAll("link[rel=stylesheet]")].map(node => node.href)
        const sheets = (await Promise.all(styles.map(style => fetch(style).then(t => t.text()))))
            .map(css => {
                const sheet = new CSSStyleSheet()
                sheet.replaceSync(css)
                return sheet
            })
        console.log("Loaded", styles, ", relulting in the following css", sheets)
        el.renderRoot.adoptedStyleSheets = sheets
    })

    setTimeout(() => dispatch(el, "loaded", true), 50)
    return html`${unsafeHTML(decoratedWithStyles(home))}`
}, {
    defaults: {
        page: ""
    }
})

pureLit("item-tag", ({ color, selected }) => html`<div class="${color} ${selected ? "active" : ""}"><slot></slot></div>`, {
    styles: itemTag,
    defaults: {
        color: "",
        selected: false
    }
});

pureLit("blog-posts", async ({ category }) => {
    if (category === "") return html``
    const response = await fetch(`/api/posts/${category.toLocaleLowerCase().replace(/\s/gi, "-")}`)
    if (response.status === 404) return html``
    const posts = await response.json()

    return html`
        <h3>Read some articles about it</h3>
        <div>
        ${posts.map(post => html`<a href=${post.link}><article>
            <h4>${post.title}</h4>
            <p>${post.description}</p>
        </article></a>`)}
        </div>
    `
},
    {
        styles: blogList,
        defaults: {
            category: ""
        }
    })


pureLit("filter-elements", (element) => {
    const { get, set } = useState(element, "")

    const colors = ["cyan", "green", "orange", "pink", "purple", "red", "yellow"];

    return element.items.map((item, index) =>
        html`<item-tag 
            color=${colors[index % colors.length]} 
            ?selected=${get() === item}
            @click=${() => {
                const val = get() === item ? "" : item;
                set(val), dispatch(element, "selected", val)
            }}>
                #${item}
        </item-tag> `
    )
}, {
    defaults: {
        items: []
    }
})


pureLit("item-list", async ({ filter, type }) => {
    if (type === "") return html``;
    const items = await fetch(`/data/${type}.json`).then(r => r.json())
    const item = ({ key, title, thumb, time, context, job }, index) => html`
        <div style="width: ${30 - index * 3}vw;">
            <div class="front">
                <img src="${thumb}" title="${title}" alt="${title}" />
                <div class="year">${time}</div>
            </div>
            <div class="back">
                <h5>${title}</h5>
                <div>${job}</div>
                <span>${context.map(c => html`#${c} `)}</span>
            </div>
        </div>
    `
    return html`${items.filter(({ context }) => context.some(context => context.includes(filter))).map(item)}`
}, {
    styles: itemList,
    defaults: {
        filter: "",
        type: ""
    }
})

pureLit("item-selection", (element) => {
    const { get, set } = useState(element, "")
    const { filters, type } = element;
    return html`
        <slot></slot>
        <h4>Filter by
            <filter-elements 
                @selected=${(e) => set(e.detail)}
                .items=${filters}>
            </filter-elements>
        </h4>
        <item-list filter=${get()} type=${type}></item-list>
        <blog-posts category=${get()}></blog-posts>
    `
}, {
    defaults: {
        filters: [],
        type: ""
    }
})

pureLit("link-tag", ({ color, url }) => html`<a class="${color}" href="${url}"><slot></slot></a>`, {
    styles: itemTag,
    defaults: {
        color: "",
        url: ""
    }
});

pureLit("link-elements", (element) => {
    const colors = ["cyan", "green", "orange", "pink", "purple", "red", "yellow"];
    return element.items.map((item, index) =>
        html`<link-tag 
            color=${colors[index % colors.length]} 
            url=${`/blog/categories/${item}`}>
                #${item}
        </link-tag> `
    )
}, {
    defaults: {
        items: []
    }
})

pureLit("explore-matthias", (element) => {
    const { filters, type } = element;
    return html`
        <slot></slot>
        <h4>
            <link-elements .items=${filters}>
            </link-elements>
        </h4>
    `
}, {
    defaults: {
        filters: ["leadership", "software-development", "architecture"],
        type: ""
    }
})