import { css } from "lit";


export const blogList = css`
div {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
}
@media (max-width: 1050px) and (min-width: 768px) {
  div {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 1800px) and (min-width: 1051px) {
  div {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 1800px) {
  div {
    grid-template-columns: repeat(4, 1fr);
  }
}
a {
    text-decoration:none;
}
article {
    background-color: #282a36;
    color: white;
    text-align: justify;
    padding: 1.5em 1.3em;
}
h4 {
    margin: 0;
    line-height: 0.8;
    font-size: 0.8em;
}
p {
    font-size: .7em;
    line-height: 0.8;
    margin: 1.2em 0;
}
`

export const itemTag = css`
a, div {
    display:inline-block;
    color: white;
    padding: 8px;
    background-color: #282a36;
    text-decoration:none;
    cursor: pointer;
}
.cyan {
    color: #80ffea;
}
.green {
    color: #8aff80;
}
.orange {
    color: #ffca80;
}
.pink {
    color: #ff80bf;
}
.purple {
    color: #9580ff;
}
.red {
    color: #ff9580;
}
.yellow {
    color: #ffff80;
}
.active {
    background-color: #414558;
}
`

export const itemList =  css`
:host {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    gap: 2rem;
}
:host > div {
    align-items: flex-end;
    display: block;
    flex: auto;
    position: relative;
    text-decoration: none;
    width: 30vw;
    max-width: 30vw;
    height: 30vh;
    transition: all 350ms ease;
    transform-style: preserve-3d;
}
:host > div:hover {
    transform: rotateX(-90deg);
}
.front, .back {
    width: 100%;
    height: 100%;
    position: relative;
}
.front {
    background: #414558;
  transform: translateZ(15vh);
}
.back {
    padding-top: 1rem;
    background: #282a36;
    color: white;
    transform: rotateX(90deg) translateZ(37vh);
    line-height: 1rem;
    position: relative;
}
.back span {
    display: block;
    font-size: small;
}
.back a {
    padding-top: 2vh;
    display: block;
    color: white;
    font-weight: bold;
    text-decoration: underline
}
.back a:hover {
    text-decoration: none;
}
img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    opacity: 0.95;
}
.year {
    text-decoration: none !important;
    font-size: 5vw;
    color: white;
    position: absolute;
    bottom: 51px;
    right: 0px;
    text-shadow: -1px -1px 0 #414558, 1px -1px 0 #414558, -1px 1px 0 #414558, 1px 1px 0 #414558;
}

`